import { post, get } from '@http/axios'
// export const getApiList = () => post('/api/common/public/routelist')

// 医院列表
export const getHospitalList = () => get('/api/doctor-platform/hospital/list')

// 医院禁用启用
export const disableHospitalStatus = (params) => post(`/api/doctor-platform/hospital/${params.type}/${params.id}`)

// 添加医院
export const createHospital = (params) => post('/api/doctor-platform/hospital/create', params)

// 医院信息
export const getHospitalInfo = (id) => get(`/api/doctor-platform/hospital/info?hospitalId=${id}`)

// 修改医院信息
export const updateHospitalInfo = (params) => post('/api/doctor-platform/hospital/update', params)

// 获取新建医生和修改医生信息验证码
export const getDoctorSMS = (params) => post('/api/doctor-platform/doctor/sendSMS', params)

// 获取医院二维码
export const getHospitalQrcode = (id) => get(`/api/doctor-platform/hospital/inviteQrcode/${id}`, { responseType: 'blob' })
