<template>
  <div class="patient-list">
  <el-drawer
    :title="isEdit ? '编辑医生信息':'新增医生信息'"
    size="420px;"
    :visible="visible"
    @close="handleClose"
    :wrapperClosable="false"
    :close-on-press-escape="false">
    <el-form :model="ruleForm" size="small" label-position="left" status-icon :rules="rules" ref="ruleForm" label-width="62px" class="doctor-info">
      <el-form-item label="姓名" prop="doctorName">
        <el-input class="form-ipt" placeholder="请输入医生姓名" v-model="ruleForm.doctorName"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="username">
        <el-input class="form-ipt" placeholder="请输入登录账号" v-model="ruleForm.username">
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="passwordTest">
        <el-input class="form-ipt" placeholder="请输入登录密码" type="password" @focus='handlerIptClick' :readonly="readonly" v-model="ruleForm.passwordTest" @input="onChangePassword"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input class="form-ipt" placeholder="请输入手机号" v-model="ruleForm.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" @input="onInputVerifyCode"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verifyCode">
        <el-col :span="16">
          <el-input class="form-ipt-verification-code" placeholder="请输入验证码" oninput="value=value.replace(/[^\d.]/g,'')" maxlength="4" v-model="ruleForm.verifyCode" :disabled="isDisabledVerifyCode" @input="onInputVerifyCode"></el-input>
        </el-col>
        <el-col :span="6">
          <el-button class="form-btn-verification-code" :disabled="disabledVerCodeBtn" @click="handleVerificationCode(ruleForm.phone)">{{countDownText}}</el-button>
        </el-col>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button class="footer-btn" @click="handleClose()">取消</el-button>
      <el-button class="footer-btn" type="primary" @click="handleSave()">保存</el-button>
    </div>
  </el-drawer>
  <!-- <el-dialog
    title="请确认开户信息"
    :visible.sync="dialogVisible"
    width="30%">
      <span>姓名：{{doctorData.doctorName}}</span>
      <br>
      <span>账号：{{doctorData.username}}</span>
      <br>
      <span>手机号：{{phone}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createDoctorAccount()">确 定</el-button>
      </span>
  </el-dialog> -->
  </div>
</template>

<script>
// import Vue from 'vue'
import { createDoctorAccount, editDoctorAccount } from '@/service/module/doctor'
import { getDoctorSMS } from '@/service/module/hospitalManage'
import md5 from 'js-md5'
import { getEditDoctorInfo } from '@/service/module/common'
export default {
  components: {

  },
  data () {
    return {
      ruleForm: {
        doctorName: '',
        username: '',
        password: '',
        passwordTest: '',
        isChangePassword: 0
      },
      rules: {
        doctorName: [
          { required: true, message: '请输入医生姓名', trigger: 'change' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'change' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        username: [
          { required: true, message: '请输入登录账号', trigger: 'change' }, { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'change' }, { pattern: /^[A-Za-z\d]+$/, message: '账号格式有误，请输入登录账号，例如：zhenxy123' }
        ],
        passwordTest: [
          { required: true, message: '请输入登陆密码', trigger: 'change' },
          { min: 6, max: 20, message: '长度在 8 到 20 个字符', trigger: 'change' },
          { pattern: /(?!.*\s)(?!^[0-9]+$)(?!^[^A-Za-z0-9]+$)(?!^[^A-Za-z.*\s]+$)(?!^[^0-9.*\s]+$)^.{8,20}$/, message: '密码格式有误，请输入8-20位英文、数字、符号（.和_）' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'change' }
        ]
      },
      // dialogVisible: false,
      countDownText: '获取验证码',
      disabledVerCodeBtn: false,

      // phone: ''
      readonly: true,

      isDisabledVerifyCode: true,

      timer: null
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    isEdit: {
      default: false,
      type: Boolean
    },
    doctorData: {
      type: Object,
      default: null
    }
  },
  watch: {
    visible (val) {
      if (val) {
        // this.$refs.ruleForm.resetFields()
        clearTimeout(this.timer)
        this.countDownText = '获取验证码'
        this.disabledVerCodeBtn = false
        this.isDisabledVerifyCode = true
        if (this.isEdit) {
          getEditDoctorInfo(this.doctorData.id).then(res => {
            if (res) {
              this.ruleForm = res.data
              this.phone = res.data.phone
              this.ruleForm.isChangePassword = 0
              this.$set(this.ruleForm, 'passwordTest', 'pW000000')
            }
          })
        } else {
          this.ruleForm = {
            doctorName: '',
            username: '',
            password: '',
            passwordTest: '',
            isChangePassword: 0
          }
        }
      }
    }
  },
  methods: {
    handlerIptClick () {
      this.readonly = false
    },
    handleClose () {
      this.$refs.ruleForm.resetFields()
      this.$emit('update:visible', false)
    },
    handleVerificationCode (phone) {
      this.disabledVerCodeBtn = true
      getDoctorSMS({
        phone: phone
      }).then(res => {
        if (res.code === 200) {
          this.isDisabledVerifyCode = false
          let val = 60
          this.timer = setInterval(() => {
            if (val === 0) {
              this.countDownText = '获取验证码'
              this.disabledVerCodeBtn = false
              clearTimeout(this.timer)
            } else {
              val--
              this.countDownText = val + 's'
            }
          }, 1000)
        } else {
          this.$message.error(res.message)
          this.disabledVerCodeBtn = false
        }
      })
    },
    handleSave () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.createDoctorAccount()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    createDoctorAccount () {
      this.ruleForm.hospitalId = this.$route.query.id
      if (this.isEdit) {
        // 没改密码
        if (this.ruleForm.isChangePassword === 0) {
          this.ruleForm.password = ''
        } else {
          if (this.ruleForm.passwordTest) {
            this.ruleForm.password = md5((this.ruleForm.passwordTest).toString())
          }
        }
        delete this.ruleForm.passwordTest
        editDoctorAccount(this.ruleForm).then(res => {
          if (res.code === 200) {
            this.$emit('update:visible', false)
            this.$emit('saveSuccess', false)
            this.$message.success(res.message)
            delete this.ruleForm.passwordTest
          } else {
            this.$set(this.ruleForm, 'passwordTest', 'pW000000')
            this.$message.error(res.message)
          }
        }).catch((err) => {
          this.$set(this.ruleForm, 'passwordTest', 'pW000000')
          this.$message.error(err)
        })
      } else {
        let passWord = ''
        if (this.ruleForm.passwordTest) {
          this.ruleForm.password = md5((this.ruleForm.passwordTest).toString())
          passWord = this.ruleForm.passwordTest
        }
        delete this.ruleForm.passwordTest
        delete this.ruleForm.isChangePassword
        createDoctorAccount(this.ruleForm).then(res => {
          if (res.code === 200) {
            this.$emit('update:visible', false)
            this.$emit('saveSuccess', false)
            this.$message.success(res.message)
          } else {
            if (passWord) {
              this.$set(this.ruleForm, 'passwordTest', passWord)
            }

            this.$message.error(res.message)
          }
        }).catch((err) => {
          this.$message.error(err)
        })
      }
    },
    onChangePassword (val) {
      this.$forceUpdate()
      if (this.isEdit) {
        this.ruleForm.isChangePassword = 1
        this.$set(this.ruleForm, 'password', val)
      }
    },
    onInputVerifyCode () {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="scss">
.demo-drawer__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background-color: white;
}
.footer-btn {
  width: 74px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.form-ipt {
  // width: 426px;
  height: 32px;
  line-height: 32px;
}
.form-ipt-verification-code {
  width: 179px;
}
.form-btn-verification-code {
  width: 102px;
  height: 32px;
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

// ::v-deep .el-input {
//   // width: 426px;
//   height: 32px;
//   line-height: 32px;
// }
::v-deep .el-form-item__label  {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  padding: 0 8px 0 0;
  text-align: left;
}
::v-deep .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
  margin-bottom: 24px;
}
.doctor-info {
  margin: 24px;
}
</style>
