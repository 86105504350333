<template>
  <div style="margin:0 20px">
    <el-scrollbar style="height: 100%">
      <div>
        <span class="title-hospital">医院管理 / </span>
        <span class="title-doctor">医生管理 - {{hospitalName}}</span>
      </div>
        <div class="search-content">
          <el-button size="small" @click="handleBack">返回</el-button>
          <el-button v-if="addDoctorHandle" size="small" type="primary" @click="openAccount">医生开户</el-button>
        </div>
        <table-comp
          :table-opts="tableOpts"
          :data="tableData"
          :page="currentPage"
          :total="totalPage"
          :page-size="pageSize"
          :show-number="true"
          :open-handle="true"
          @other_2="handleEdit"
          :handle-opts="handleOpts"
          @handleSizeChange="sizeChange"
          @handleCurrentChange="pageChange"
        >
        </table-comp>
        <DoctorOA :visible.sync="visibleDoctorOA" :isEdit="isEdit" :doctorData="doctorData" @saveSuccess="saveSuccess"></DoctorOA>
    </el-scrollbar>
  </div>
</template>

<script>
import TableComp from '@/components/Table/Table'
import DoctorOA from './Page_DoctorOA/index.vue'
import { getDoctorList, opnDoctorAccount } from '@/service/module/doctor'
export default {
  components: {
    TableComp,
    DoctorOA
  },
  data () {
    return {
      tableOpts: [ // 列表表头设置
        {
          label: '医生名称',
          prop: 'doctorName',
          render: (h, params) => {
            const doctorName = params.row.doctorName
            const doctorNameText = '-'
            if (!doctorName) {
              return h('span', {}, doctorNameText)
            } else {
              return h('span', {}, doctorName)
            }
          }
        },
        {
          label: '账号',
          prop: 'username',
          render: (h, params) => {
            const username = params.row.username
            const usernameText = '-'
            if (!username) {
              return h('span', {}, usernameText)
            } else {
              return h('span', {}, username)
            }
          }
        },
        {
          label: '创建人',
          prop: 'creater',
          render: (h, params) => {
            const creater = params.row.creatorName
            const createrText = '-'
            if (!creater) {
              return h('span', {}, createrText)
            } else {
              return h('span', {}, creater)
            }
          }
        },
        {
          label: '开户时间',
          prop: 'createTime',
          render: (h, params) => {
            var moment = require('moment')
            const createTime = moment.unix(params.row.createTime / 1000).format('Y-MM-DD HH:mm:ss')

            const createTimeText = '-'
            if (!params.row.createTime) {
              return h('span', {}, createTimeText)
            } else {
              return h('span', {}, createTime)
            }
          }
        }
      ],
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数

      visibleDoctorOA: false,
      hospitalName: this.$route.query.name,
      isEdit: false,

      addDoctorHandle: false,

      doctorData: {}
    }
  },
  mounted () {
    this.getDoctorList(1, this.pageSize)
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'hospital-mgr:add-doctor') {
        this.addDoctorHandle = true
      }
      if (i === 'hospital-mgr:edit-doctor') {
        this.handleOpts.other_2 = {
          type: 'text',
          text: '编辑'
        }
      }
      if (i === 'hospital-mgr:change-doctor-status') {
        this.tableOpts.push({
          label: '账号状态',
          prop: 'isEnable',
          type: 'switch',
          active_value: 1,
          inactive_value: 0,
          handler: (data) => {
            this.tableData[data].isEnable === 0 ? this.tableData[data].isEnable = 1 : this.tableData[data].isEnable = 0
            this.$confirm(`请确认是否${this.tableData[data].isEnable === 1
            ? '冻结'
            : '开启'}该账号?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              var type = this.tableData[data].isEnable === 0 ? 'enable' : 'disable'

              this.opnDoctorAccount(type, this.tableData[data].id)
            }).catch(() => {
              console.log('取消冻结操作')
            })
          }
        })
      }
    })
  },
  methods: {
    getDoctorList (pageNum, pageSize) {
      getDoctorList({
        pageNum: pageNum,
        pageSize: pageSize,
        hospitalId: this.$route.query.id
      }).then(res => {
        if (res.data) {
          this.tableData = res.data.results
          this.totalPage = res.data.total
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getDoctorList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getDoctorList(val, this.pageSize)
    },
    openAccount (val) {
      console.log(val)
      this.visibleDoctorOA = true
      this.isEdit = false
    },
    handleBack () {
      this.$router.push({
        name: 'hospitalManage'
      })
    },
    opnDoctorAccount (type, id) {
      opnDoctorAccount({
        type: type,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.getDoctorList()
          this.$message.success(res.message)
        } else {
          this.getDoctorList()
          this.$message.error(res.message)
        }
      })
    },
    handleEdit (data) {
      this.isEdit = true
      this.visibleDoctorOA = true
      this.doctorData = data
    },
    saveSuccess () {
      this.getDoctorList(1, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.search-content {
  float: right;
  margin-bottom: 12px;
}
.title-hospital, .title-doctor {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
}
.title-doctor {
  color: #303133;
}
</style>
