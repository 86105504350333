import { get, post } from '@http/axios'

// 获取医生账号列表
export const getDoctorList = (params) => get('/api/doctor-platform/doctor/list', { params })

// 操作医生账户
export const opnDoctorAccount = (params) => post(`/api/doctor-platform/doctor/${params.type}/${params.id}`)

// 创建医生账户
export const createDoctorAccount = (params) => post('/api/doctor-platform/doctor/create', params)

// 修改医生信息
export const editDoctorAccount = (params) => post('/api/doctor-platform/doctor/update', params)

// 修改密码
export const changePassword = (params) => post('/api/doctor-platform/doctor/updatepwd', params)
